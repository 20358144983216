.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__skills-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen  and (max-width: 1300px){
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}

.app__skills-list {
    width: 30%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;


    @media screen  and (max-width: 1300px){
        margin-right: 0;
        width: 80%;
        justify-content: center;
        margin-top: 30px;
    }

    p {
        width: 100%;
    }
}

.app__skills-item {
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
    width: 90px;

    div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: var(--gray-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        img {
            width: 50%;
            height: 50%;
        }

        &:hover {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
        }


        @media screen  and (max-width: 500px){
            width: 70px;
            height: 70px;
        }
    }

    p {
        font-weight: 500;
        margin-top: 0.5rem;
    }

    @media screen and (min-width: 2000px){
        margin: 1rem 2rem;

        p {
            margin-top: 1rem;
        }
    }
}