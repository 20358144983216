.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    img {
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        width: 380px;
        margin: 2rem 4rem;

        img {
            height: 320px;
        }
    }
}

.app__intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app__description-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
        width: 50px;
        height: 50px;
        margin-right: 20px;

        @media screen and (max-width:500px){
            opacity: 0;
            width: 0;
        }
    }
}

.app__description {
    display: flex;
    text-align: left;
    justify-content: stretch;
    flex-wrap: wrap;
    font-weight: 800;
    font-size: 1rem;
    color: var(--gray-color);
    width: 60%;
    margin-left: 10;
}