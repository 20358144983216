#experience {
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;

    background-repeat: repeat;
    background-position: center;
}

.app__experience{
    flex: 1;
    width: 100%;
    flex-direction: row;
}

.app__experience-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;


    .app__experience-item{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 300px;
        width: 300px;
        margin: 1rem;
        padding: 1.5rem;
        border-radius: 0.5rem;
        background-color: #fff;
        padding: 0.5rem 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        h4 {
            line-height: 1.5;
        }

        &:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
        }

        @media screen and (max-width: 500px){
            width: 250px;
            height: auto;
        }
    }
}