#education {
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;

    background-repeat: repeat;
    background-position: center;

}

.app__education{
    flex: 1;
    width: 100%;
    flex-direction: row;
}

.app__education-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
        margin-top: 1rem;
        line-height: 1.5;
    }

}

.app__education-item {
    flex:1;
    width: 270px;
    flex-direction: row;
    margin: 1.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: #000;
    transition: all 0.3s ease-in-out;
    margin: 0 20;

    @media screen and (min-width: 2000px) {
        height: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
        height: 100%;
        padding: 1rem;
    }
}

.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.timeline-container::after {
    background-color: #313bac;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;

    @media screen and (max-width: 500px){
        left: 40px;
    }
}

.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;

    @media screen and (max-width: 500px){
        padding-left: 30px;
        padding-right: 0;
        margin-left: 7px;
        width: 400px;
    }
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    text-align: left;
    padding-left: 30px;
    padding-right: 0;

    @media screen and (max-width: 500px){
        align-self: auto;
        justify-content: flex-start;
        text-align: left;
        padding-left: 30px;
        padding-right: 0;
        margin-left: 42px;
        width: 400px;
    }
}

.timeline-item-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #fff;
    flex-direction: column;
    padding: 15px;
    position: relative;
    width: 500px;


    @media screen  and (max-width: 1200px){
        width: 400px;
    }

    @media screen  and (max-width: 500px){
        width: 300px;
    }
}

.timeline-item-content::after {
    content: ' ';
    background-color: #fff;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;

}

.timeline-item:nth-child(odd) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(even) .timeline-item-content {
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);

}

.timeline-item:nth-child(even) .timeline-item-content::after {
    @media screen and (max-width: 500px){
        right: auto;
        left: -7.5px;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

}




.timeline-item-content .circle {
    border: 3px solid #313bac;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 1;

    @media screen and (max-width: 500px){
        left: -40px;
        z-index: 1;
    }
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}
